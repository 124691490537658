import React from 'react';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { RedirectedOffersI } from 'src/e2e-redesign/business-logic/e2e-business-logic';
import ThemedDialog from 'src/e2e-redesign/components/ThemedDialog/ThemedDialog';
import styles from 'src/e2e-redesign/components/ThemedDialog/ThemedDialog.module.css';

interface SynchronyRedirectModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  paragraph: string;
  imageUrl: string;
  submittedTabs: RedirectedOffersI[];
}

const RedirectModal: React.FC<SynchronyRedirectModalProps> = (props: SynchronyRedirectModalProps) => {
  const { open, onClose, title, paragraph, imageUrl, submittedTabs } = props;
  if (submittedTabs && submittedTabs.length > 0) {
    const urlObj = submittedTabs[0];
    const content = `
    <div style="text-align: center;">
      <h2>${title}</h2>
      <p>${paragraph}</p>
      <p style="font-size: 15px"><a href="${urlObj.redirect_url}" target="_blank">Click here </a>to complete your application securely with Synchrony.</p>
      <img src="${imageUrl}" alt="Redirecting" style="max-width: 100%; margin-top: 20px;" />
    </div>
  `;

    // return <ThemedDialog open={open} onClose={onClose} content={content} />;
    /**
     * Removing the ability for the user to close
     */
    return (
      <Dialog
        {...props}
        onClose={onClose}
        // onClose={() => {
        //   return false;
        // }}
        // disableEscapeKeyDown
        // onBackdropClick={(e) => e.preventDefault()}
      >
        <DialogContent>
          <div className={styles.htmlContent} dangerouslySetInnerHTML={{ __html: content }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  }

  const without = `
    <div style="text-align: center;">
      <h2>${title}</h2>
      <p>${paragraph}</p>
      <img src="${imageUrl}" alt="Redirecting" style="max-width: 100%; margin-top: 20px;" />
    </div>
  `;
  return (
    <Dialog {...props} onClose={onClose}>
      <DialogContent>
        <div className={styles.htmlContent} dangerouslySetInnerHTML={{ __html: without }} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
  // return <ThemedDialog open={open} onClose={onClose} content={without} />;
};

export default RedirectModal;
