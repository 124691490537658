import { useCallback, useEffect, useRef, useState } from 'react';
import { random } from 'lodash';
import { E2eBaseCallDetailsI, e2eGetBaseAasoRequests } from 'src/api/query-logic/e2e-query-base-calls';
import { DevSubmissionEnums } from 'src/components/e2e/lender-status-dev-menu/lender-status-dev-menu';
import {
  e2eLogic_getValidE2eLenders,
  e2eLogic_getValidE2eLendersForAutoApproval,
  e2eLogic_redirectUser,
  RedirectedOffersI,
} from 'src/e2e-redesign/business-logic/e2e-business-logic';
import {
  e2eConfigLogic_buildAppOffersObject,
  e2eConfigLogic_buildNotOffers,
  ProviderOffersI,
} from 'src/e2e-redesign/business-logic/e2e-configuration-object-logic';
import { E2eChunkedDisclosuresI } from 'src/e2e-redesign/business-logic/e2e-disclosure-logic';
import { E2eLogicObjectsWithAasoParams } from 'src/e2e-redesign/business-logic/e2e-logic-utils';
import { e2ePathCondLogic_routeBackToSelectApp } from 'src/e2e-redesign/business-logic/e2e-path-conditions-logic';
import {
  e2eSubLogic_checkSubmissionsWhereErrorOrDeclined,
  e2eSubLogic_continuePipingWaterfall,
  e2eSubLogic_defaultMoveForwardLogic,
  e2eSubLogic_handleKickoff,
  e2eSubLogic_haveBeenDeclinedByAllE2eLenders,
  e2eSubLogic_haveReceivedOneOrMultipleOffers,
  e2eSubLogic_haveResponsesComeBackFromAllCurrentPathE2eLenders,
  e2eSubLogic_isLenderRedirect,
  e2eSubLogic_moveForwardShowDefaultOrSpecial,
  e2eSubLogic_specialMoveForwardLogic,
  MoveForwardLogicParams,
} from 'src/e2e-redesign/business-logic/e2e-submission-logic';
import { e2eValidationLogic_lenderResponsePage } from 'src/e2e-redesign/business-logic/e2e-validation-logic';
import { ComponentLogicI } from 'src/e2e-redesign/interfaces/e2e-base-interfaces';
import { LaaSettingsI } from 'src/e2e-redesign/interfaces/laa-settings.interface';
import useE2eStore from 'src/e2e-store';
import useErrorHandler from 'src/hooks/use-error-handler';
import { SocketEvents, useSocket } from 'src/hooks/use-socket';
import { AASO, AasoKickOffDetailsI } from 'src/interfaces/aaso.interfaces';
import { E2eAppSettingsFormPathsI, E2eAppSettingsFormsI } from 'src/interfaces/application-settings.interfaces';
import { Disclosure } from 'src/interfaces/disclosures.interfaces';
import { E2eAppConfigurationsI, E2eGlobalStore, FormDocI } from 'src/interfaces/e2e-state.interfaces';
import { E2eLenderObjectDetailsI, LendersI } from 'src/interfaces/lenders.interfaces';
import { E2eLenderShowGetStatusI } from 'src/interfaces/store-settings.interfaces';
import { ApplicationSubmissionStateDto, AppSubStatusE } from 'src/interfaces/submissions.interfaces';
import { fsTrackEvent } from 'src/utils/fullstory';

export const providerRespLogic_init = (routingPackage: E2eLogicObjectsWithAasoParams) => {
  const params = routingPackage.params;
  const { store_uuid, e2e_form_id, aaso_id } = params;
  const { e2eStore } = routingPackage;
  const baseCallDetails: E2eBaseCallDetailsI = {
    store_uuid,
    e2e_form_id,
    aaso_id,
  };

  const res = e2eGetBaseAasoRequests(baseCallDetails, e2eStore, true);
  e2eValidationLogic_lenderResponsePage(routingPackage, res);
};

export enum LenderSubmissionStatusE {
  'submitted' = 'submitted',
  'single_offer' = 'single_offer',
  'multiple_offers' = 'multiple_offers',
  'no_offers' = 'no_offers',
}

export enum LenderResponseOutcomesE {
  'approved_offers' = 'approved_offers',
}
export interface GroupedOfferI {
  lender: LendersI;
  offers: ProviderOffersI[];
  notOffers: NotOffersI[];
  autoApprovals: AutoApprovalOffersI[];
  is_offer: boolean;
  is_auto?: boolean;
  lender_id: number;
  id?: any;
}
export interface ProviderResponseLogicI extends ComponentLogicI {
  fn: {
    handleApiPipe: (dev_submission: DevSubmissionEnums) => void;
    handleUnhappyPathRouting: () => void;
    handleContinueWaterfallPiping: () => void;
    handleMoveForwardAction: () => void;
  };
  v: {
    offers: ProviderOffersI[];
    notOffers: NotOffersI[];
    showNotHappyCard: boolean;
    hasRedirects: boolean;
    autoApprovals: AutoApprovalOffersI[];
    // lenderRedirects: E2eProcessApplicationJobI[];
    submittedTabs: RedirectedOffersI[];
    submissionStatus: LenderSubmissionStatusE;
    allDeclined: boolean;
    failedLenderIds: number[];
    haveE2eLendersResponded: boolean;
    showContinueWaterfallPiping: boolean;
    showMoveForwardCard: boolean;
    showErrorPage: boolean;
    combinedResponses: GroupedOfferI[];
  };
}
export interface NotOffersI {
  sub_details: ApplicationSubmissionStateDto;
  lender: LendersI;
  lender_disclosures?: Disclosure[];
  is_offer: boolean;
  is_auto?: boolean;
}
export interface AutoApprovalOffersI {
  lender: LendersI;
  lender_disclosures?: Disclosure[];
  is_offer: boolean;
  is_auto: boolean;
}

export const e2eLogic_getShowStatusIfExists = (
  laaSettings: LaaSettingsI | undefined,
  // lenderSettings: E2eLenderObjectDetailsI | undefined,
  lender: LendersI,
): E2eLenderShowGetStatusI | undefined => {
  /**
   * I have this in laa_settings and lender_settings
   * bc synchrony laa's have special rules which dictate when
   * the user may move forward with the flow
   */
  const lenderSettings: E2eLenderObjectDetailsI | undefined = lender?.api_pipe_settings?.e2e_config?.config;
  // make sure this applies to the current lender
  if (
    laaSettings &&
    Number(lender.lender_id) == Number(laaSettings.lender_id) &&
    laaSettings?.e2e_configs?.applicant_flow?.show_get_status
  ) {
    // LAA settings override lender settings
    return laaSettings && laaSettings?.e2e_configs?.applicant_flow?.show_get_status;
  }
  const lenderShowGetStatusEnabled = lenderSettings?.show_get_status?.enabled;
  if (lenderShowGetStatusEnabled) {
    return lenderSettings?.show_get_status;
  }
};

export const sortLendersByIds = (obj: GroupedOfferI[], lenderIds: number[]) => {
  return obj.sort((a, b) => {
    const indexA = lenderIds.indexOf(a.lender_id);
    const indexB = lenderIds.indexOf(b.lender_id);

    // If a lender_id is not in the lenderIds array, it will be placed at the end
    if (indexA === -1) return 1;
    if (indexB === -1) return -1;

    return indexA - indexB;
  });
};

export const canShowAutoApprovedLenders = (
  currentPath: E2eAppSettingsFormPathsI,
  currentForm: E2eAppSettingsFormsI,
  lender: LendersI,
  aaso: AASO,
) => {
  // are they in the current path
  const currentPathLenders: number[] = currentPath.lenders;
  const inCurrentPath = currentPathLenders.includes(lender.lender_id);
  if (inCurrentPath) {
    return true;
  }
  const autoApprovalLenderPath = currentForm.paths.find((p) => p.lenders.includes(lender.lender_id));
  const pastPathIds = aaso.past_path_ids;
  if (autoApprovalLenderPath && pastPathIds.includes(autoApprovalLenderPath.id)) {
    return true;
  }
  return false;
};

export const providerResLogic_functionsAndVals = (
  routingPackage: E2eLogicObjectsWithAasoParams,
): ProviderResponseLogicI => {
  const { params, e2eStore, location, navigate } = routingPackage;
  const { e2e_form_id, aaso_id } = params;
  const socket = useSocket();
  const { handleError } = useErrorHandler();
  const appConfg: E2eAppConfigurationsI | undefined = e2eStore.app_configurations;
  const formDoc: FormDocI | undefined = e2eStore.form_values;
  const submissions = useE2eStore((store: E2eGlobalStore) => store.submissions);
  const form_values = useE2eStore((store: E2eGlobalStore) => store.form_values);
  const getE2eSubmissions = useE2eStore((store: E2eGlobalStore) => store.getE2eSubmissions);
  const [submissionStatus, setSubmissionStatus] = useState<LenderSubmissionStatusE>(LenderSubmissionStatusE.submitted);
  const [offers, setOffers] = useState<ProviderOffersI[]>([]);
  const [allDeclined, setAllDeclined] = useState<boolean>(false);
  const [failedLenderIds, setFailedLenderIds] = useState<number[]>([]);
  const [hasRedirects, setHasRedirects] = useState<boolean>(false);
  const [haveE2eLendersResponded, setHaveE2eLendersResponded] = useState<boolean>(false);
  const [showMoveForwardCard, setShowMoveForwardCard] = useState<boolean>(false);
  const [submittedTabs, setSubmittedTabs] = useState<RedirectedOffersI[]>([]);
  const [showNotHappyCard, setShowNotHappyCard] = useState<boolean>(false);
  const [showContinueWaterfallPiping, setContinueWaterfallPiping] = useState<boolean>(false);
  const [pullOffer, setPullOffer] = useState<boolean>(false);
  const [notOffers, setNotOffers] = useState<NotOffersI[]>([]);
  const [autoApprovals, setAutoApprovals] = useState<AutoApprovalOffersI[]>([]);
  const [combinedResponses, setCombinedResponses] = useState<GroupedOfferI[]>([]);
  const [forceSubmission, setForceSubmission] = useState<boolean>(false);
  const [hasKickedOff, setHasKickedOff] = useState<boolean>(false);

  const aaso = useE2eStore((store: E2eGlobalStore) => store.aaso);
  const [visibleSubmissions, setVisibleSubmissions] = useState<any[]>(() => {
    return submissions?.length
      ? submissions
      : [{ form_id: aaso?.form_id, lender_response: 'pending', lender_id: 83, approved_amount: 0 }];
  });
  const [showErrorPage, setShowErrorPage] = useState<boolean>(false);
  const submittedTabsRef = useRef<RedirectedOffersI[]>(submittedTabs);
  const previousSubmissionsRef = useRef(submissions);
  const addSubmission = e2eStore.addSubmission;

  useEffect(() => {
    const lenders = appConfg?.lenders;
    const disclosures = e2eStore.disclosures;
    const currentPath = appConfg?.current_path;
    const currentForm = appConfg?.current_form;
    if (lenders && disclosures && currentPath && aaso && currentForm) {
      const autoApprovalLenders = lenders.filter((l) => l?.api_pipe_settings?.e2e_config?.auto_approval?.enabled);
      if (autoApprovalLenders) {
        // auto approved providers exists - now do we show them?
        const autoArray: AutoApprovalOffersI[] = [];
        autoApprovalLenders.forEach((l) => {
          const canShow = canShowAutoApprovedLenders(currentPath, currentForm, l, aaso);
          if (canShow) {
            const lender_disclosures = disclosures.filter((d) => d.lender_id == l?.lender_id);
            autoArray.push({
              lender: l,
              lender_disclosures,
              is_offer: false,
              is_auto: true,
            });
          }
        });
        setAutoApprovals(autoArray);
      }
    }
  }, [appConfg?.lenders, e2eStore.disclosures, appConfg?.current_path, aaso, appConfg?.current_form]);
  // Keep track of previous submissions
  useEffect(() => {
    previousSubmissionsRef.current = submissions;
  }, [submissions]);

  useEffect(() => {
    const allOffers: (ProviderOffersI | NotOffersI | AutoApprovalOffersI)[] = [
      ...offers,
      ...notOffers,
      ...autoApprovals,
    ];

    // Group by lender.lender_id
    const groupedOffers = allOffers.reduce((acc, currentOffer) => {
      const lenderId = currentOffer.lender.lender_id;
      if (!acc[lenderId]) {
        acc[lenderId] = {
          lender: currentOffer.lender,
          is_offer: currentOffer.is_offer,
          offers: [],
          notOffers: [],
          is_auto: currentOffer.is_auto,
          autoApprovals: [],
          lender_id: lenderId,
          id: `${lenderId}-${random(0, 100000)}`,
        };
      }

      if (currentOffer.is_offer) {
        acc[lenderId].offers.push(currentOffer as ProviderOffersI);
      } else if (currentOffer.is_auto) {
        acc[lenderId].autoApprovals.push(currentOffer as AutoApprovalOffersI);
      } else if (!currentOffer.is_auto && !currentOffer.is_offer) {
        acc[lenderId].notOffers.push(currentOffer as NotOffersI);
      }

      return acc;
    }, {} as Record<string, GroupedOfferI>);

    // Convert to array
    const groupedOffersArray = Object.values(groupedOffers);
    const lenderIds = appConfg?.current_path?.lenders;
    if (lenderIds) {
      const sortedLenders = sortLendersByIds(groupedOffersArray, lenderIds);
      const final = sortedLenders.map((l) => {
        return { ...l };
      });
      // setCombinedResponses(groupedOffersArray.reverse());
      setCombinedResponses(final);
    }
  }, [offers, notOffers, autoApprovals, appConfg?.current_path]);

  const haveE2eLendersRespondedRef = useRef(haveE2eLendersResponded);

  useEffect(() => {
    haveE2eLendersRespondedRef.current = haveE2eLendersResponded;
    try {
      console.log('providers responsed', haveE2eLendersResponded, aaso_id, e2e_form_id);
      if (haveE2eLendersResponded && aaso_id && e2e_form_id) {

        const lenderDetails = submissions.map(submission => ({
          lender_id: submission.lender_id,
          lender_name: submission.lender_name
        }));

        fsTrackEvent('Providers Responded', {
          aaso_id,
          e2e_form_id,
          lenders: lenderDetails
        });
      }
    } catch (e) {
      console.log('failed to set provider responded');
    }
  }, [haveE2eLendersResponded, aaso_id, e2e_form_id, submissions]);

  useEffect(() => {
    // check to see if we show the moveForwardCard
    /**
     * - check to see that the application has been submitted
     * - we need some submissions back before we can show it
     */
    if (submissions.length > 0) {
      // check if it's a redirect and then check if it is part of special logic
      if (haveE2eLendersResponded && (showContinueWaterfallPiping || showNotHappyCard)) {
        setShowMoveForwardCard(true);
      } else {
        setShowMoveForwardCard(false);
      }
    } else {
      setShowMoveForwardCard(false);
    }
  }, [formDoc, appConfg, submissions, aaso, haveE2eLendersResponded, showContinueWaterfallPiping, showNotHappyCard]);

  useEffect(() => {
    // this handles the logic of what the action will be for the MoveForwardCard
    const franchise_settings = appConfg?.franchise_settings;
    const laa_settings = appConfg?.laa_settings;
    // R:TODO E2E P0 - if the user is on their second path, we need to show all offers from all previous paths
    // handle laaSettings/franchiseSettings - this will bypass the normal flow

    /**
     * - Update the "Not Happy Path" and "Move on to additional lenders" card to be a generic card (Move Forward)
     *    - we will dictate the action of that card
     *
     * - check if laaSettings or franchiseSettings exist:
     *    - prioritize laaSettings over franchiseSettings
     *    - see if it even applies... laa_settings - if they are running the current laa_lender
     * - handle the following scenarios:
     *    - Update Status Button condition:
     *      - if lender_response = timed_out/Pending (from laa_settings.e2e_configs.show_app_status.on.lender_responses)
     *        - create functionality to hit that endpoint
     *        - !! we need to add... mark time out on timeout = true/false
     *    - Show Move Forward Card
     *      - Actions:
     *          - if lender_response = Pending or timed_out
     *            - they can move to sub_prime options
     *          - if lender_response = approved/declined
     *            - they can move
     *
     *    - if lender_response = pending
     *        - laa_settings.current_path_condition.waterfall_condition = "stop_on_pending_w_option_to_continue_next_path"
     *          - show either the "Not happy path" card or the "Continue to pipe additional lenders"
     *          - if other prime lenders exists and pending, cannot move forward with prime
     */
    if (formDoc && formDoc.data && submissions.length > 0 && appConfg) {
      if (haveE2eLendersResponded) {
        // we do this no matter what
        const failedIds = e2eSubLogic_checkSubmissionsWhereErrorOrDeclined(submissions);
        setFailedLenderIds(failedIds);
        const allD = e2eSubLogic_haveBeenDeclinedByAllE2eLenders(submissions, appConfg);
        setAllDeclined(allD);
        const outcome = e2eSubLogic_haveReceivedOneOrMultipleOffers(submissions, appConfg.lenders);
        setSubmissionStatus(outcome);
      }
      // get estimated purchase amount
      const obj: MoveForwardLogicParams = {
        haveE2eLendersResponded,
        submissions,
        appConfg,
        aaso,
        laa_settings,
        franchise_settings,
        form: formDoc.data,
      };
      const handleDefault = e2eSubLogic_moveForwardShowDefaultOrSpecial(obj);
      // TODO E2E REFACTOR - return an object, instead of setting state in these functions for better clarity
      if (handleDefault.special && haveE2eLendersResponded) {
        const out = e2eSubLogic_specialMoveForwardLogic(obj, handleDefault);
        setContinueWaterfallPiping(out.continue_waterfall);
        console.log('1', { out });
        setShowNotHappyCard(out.continue_next_path);
        if (!out.continue_next_path && !out.continue_waterfall) {
          // remove the card if both false
          setShowMoveForwardCard(false);
        } else {
          setShowMoveForwardCard(true);
        }
      } else {
        const out = e2eSubLogic_defaultMoveForwardLogic(obj);
        console.log('2', { out });
        setContinueWaterfallPiping(out.continue_waterfall);
        setShowNotHappyCard(out.continue_next_path);
        if (!out.continue_next_path && !out.continue_waterfall) {
          setShowMoveForwardCard(false);
        } else {
          setShowMoveForwardCard(true);
        }
      }
    }
  }, [formDoc, appConfg, submissions, aaso, haveE2eLendersResponded]);

  useEffect(() => {
    // check to see if only one auto-approval lender exists
    if (haveE2eLendersResponded && appConfg?.lenders) {
      const validAutoApprovalsForCurrentPath = e2eLogic_getValidE2eLendersForAutoApproval(
        appConfg.lenders,
        appConfg.current_path,
      );
      const validE2eLendersOnCurrentPath = e2eLogic_getValidE2eLenders(appConfg.lenders, appConfg.current_path, false);
      if (validAutoApprovalsForCurrentPath.length === validE2eLendersOnCurrentPath.length) {
        const outcome = e2eSubLogic_haveReceivedOneOrMultipleOffers(submissions, appConfg.lenders);
        setSubmissionStatus(outcome);
      }
    }
  }, [appConfg, haveE2eLendersResponded, submissions]);
  useEffect(() => {
    if (appConfg && form_values) {
      const haveResponsesComeBackFromAllLenders = e2eSubLogic_haveResponsesComeBackFromAllCurrentPathE2eLenders(
        submissions,
        appConfg,
        form_values,
        forceSubmission,
      );
      if (haveResponsesComeBackFromAllLenders) {
        setHaveE2eLendersResponded(true);
      } else {
        setHaveE2eLendersResponded(false);
      }
    }
  }, [submissions, appConfg, form_values, forceSubmission]);

  useEffect(() => {
    if (appConfg) {
      // this does an initial check just to see if there are lender e2e redirects
      const isLenderRedirect = e2eSubLogic_isLenderRedirect(appConfg);
      if (isLenderRedirect.length > 0) {
        setHasRedirects(true);
      }
      // Now we know if we redirect or not...
    }
  }, [appConfg]);

  useEffect(() => {
    if (submissions?.length && !hasRedirects) {
      setVisibleSubmissions(submissions);
    }
  }, [submissions, hasRedirects]);

  useEffect(() => {
    submittedTabsRef.current = submittedTabs;
  }, [submittedTabs]);

  const updateQueryParams = (key: string, value: string) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(key, value);
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  useEffect(() => {
    // when the redirect url gets hit, it will pull the latest app status
    setTimeout(() => {
      // add a timeout to give the lender some time to update
      const searchParams = new URLSearchParams(location.search);
      const po = searchParams.get('pullOffer');
      // when pullOffer exists, the lender has redirected us back so we should pull the data in the even websocket fails
      if (po && submissions.length > 0 && !pullOffer) {
        for (const [key, value] of searchParams.entries()) {
          // Check if the key matches "lenderId" followed by a number
          const match = key.match(/^lenderId(\d+)$/);
          if (match && value === 'true') {
            const lenderId = parseInt(match[1], 10);
            if (lenderId) {
              addSubmission(routingPackage.params, [AppSubStatusE.redirected], lenderId);
              setPullOffer(true);
            }
          }
        }
      }
    }, 4000);
  }, [submissions, pullOffer]);

  useEffect(() => {
    if (submissions.length > 0 && submissions.length > 0) {
      const params = new URLSearchParams(location.search);
      const pullOffer = params.get('pullOffer');
      if (pullOffer == null) {
        const subTabs = e2eLogic_redirectUser(submissions, submittedTabsRef.current, appConfg?.current_path);
        for (const tab of subTabs) {
          updateQueryParams(`lenderId${tab.lender_id}`, 'true');
        }
        setSubmittedTabs((prevTabs) => [...prevTabs, ...subTabs]);
      }
    }
  }, [submissions, appConfg?.current_path]);

  // console.log('SOCKET CONNECTED', socket.socket?.connected);
  useEffect(() => {
    socket.onCallback(SocketEvents.WS_EVENT_UPDATE_API_STATUS_PAGE, (p: any) => {
      if (aaso_id === p.aaso_id) {
        getE2eSubmissions(aaso_id, e2e_form_id);
      }
    });
    return () => {
      socket.off(SocketEvents.WS_EVENT_UPDATE_API_STATUS_PAGE);
    };
  }, []);

  useEffect(() => {
    console.log('fire');
    if (e2e_form_id && aaso_id) {
      console.log('null');
      try {
        console.log(
          'provider response view reached',
          routingPackage?.params?.aaso_id,
          routingPackage?.params?.e2e_form_id,
        );
        fsTrackEvent('Provider Response View Reached', {
          aaso_id: routingPackage?.params?.aaso_id,
          e2e_form_id: routingPackage.params.e2e_form_id,
        });
      } catch (e) {
        console.log('fs fail', e);
      }
    }
  }, [e2e_form_id, aaso_id]);

  useEffect(() => {
    /**
     * We need to create a new object to handle this since we're showing 1 card PER offer and not lender
     */

    if (submissions.length > 0 && e2eStore.lenders) {
      // TODO E2E P2 - optimize to run through lender offers
      const provOffers = e2eConfigLogic_buildAppOffersObject(submissions, e2eStore.lenders, e2eStore.disclosures);
      if (provOffers?.length > 0) {
        setOffers(provOffers);
      }
      const other: NotOffersI[] = e2eConfigLogic_buildNotOffers(submissions, e2eStore.lenders, e2eStore.disclosures);
      if (other?.length > 0) {
        setNotOffers(other);
      }
    }
  }, [submissions, e2eStore.lenders, failedLenderIds, e2eStore.disclosures]);

  useEffect(() => {
    if (aaso?.form_id) {
      getE2eSubmissions(aaso_id, e2e_form_id);
    }
  }, [aaso.form_id]);

  useEffect(() => {
    // check to see if all lenders have errors to handle showing error screen
    if (haveE2eLendersResponded && appConfg) {
      // all lenders have responded for current path
      let allErrorsOnCurrentPath = true;
      const currentPathLenders = appConfg.current_path?.lenders;
      const currenPathSubmissions = submissions.filter((s) => currentPathLenders?.includes(Number(s.lender_id)));
      currenPathSubmissions.every((s) => {
        if (s.submission_state !== AppSubStatusE.error) {
          allErrorsOnCurrentPath = false;
          return false; // break the loop
        }
        return true;
      });
      if (allErrorsOnCurrentPath && currenPathSubmissions.length > 0) {
        // all submission on current path have errored out - now check to see if there is another path available
        setShowErrorPage(true);
        // const pastPathsIds: string[] = aaso.past_path_ids;
        // const currentPathId = aaso.current_path_id;
        // const allPathsRan = [...new Set([...pastPathsIds, currentPathId])];
        // const allPathsAvailable = appConfg?.current_form?.paths;
        // if (allPathsRan.length === allPathsAvailable?.length) {
        //   // all paths have been ran
        //   setShowErrorPage(true);
        // } else {
        //   // not all paths have been ran
        //   setShowErrorPage(false);
        // }
      } else {
        // not all errors
        setShowErrorPage(false);
      }
    }
  }, [haveE2eLendersResponded, submissions, aaso, appConfg?.current_form]);

  useEffect(() => {
    if (!hasKickedOff && params && process.env.REACT_APP_ENVIRONMENT === 'production') {
      try {
        handleApiPipe();
        setHasKickedOff(true);
      } catch (e) {
        console.log('failed', e);
      }
    }
  }, []);

  const pollForSubmission = useCallback(
    (kickOffObj: Partial<AasoKickOffDetailsI>): void => {
      if (kickOffObj) {
        const e2eJobs = kickOffObj.e2e_bull_jobs;
        if (e2eJobs && e2eJobs.length > 0) {
          const { aaso_id, e2e_form_id } = params;
          // R:TODO E2E P3 - this could be improved by accounting for condition logic
          /**
           * if a condition is met then we won't be grabbing nay of the other lenders
           * - this is just checking to see if e2e lenders have been submitted
           */
          const checkIfBack = () => {
            // I think this actually accounts for everything
            if (haveE2eLendersRespondedRef.current) {
              return true;
            } else {
              return false;
            }
          };

          const intervalDuration = 15000; // 15 seconds in milliseconds
          const stopAfter = 10 * 60 * 1000; // 10 minutes in milliseconds

          const intervalId = setInterval(() => {
            const allResponded = checkIfBack();
            if (allResponded) {
              clearInterval(intervalId);
            } else {
              getE2eSubmissions(aaso_id, e2e_form_id);
            }
          }, intervalDuration);

          setTimeout(() => {
            clearInterval(intervalId);
            setHaveE2eLendersResponded(true);
          }, stopAfter);
        }
      }
    },
    [visibleSubmissions, haveE2eLendersResponded, haveE2eLendersRespondedRef, params],
  );

  const handleApiPipe = async (dev_submission?: DevSubmissionEnums) => {
    return await e2eSubLogic_handleKickoff(pollForSubmission, handleError, params, dev_submission);
  };

  const handleUnhappyPathRouting = useCallback(() => {
    // OLD WAY
    // e2ePathCondLogic_routeToNewPath(routingPackage, e2e_form_id);
    // NEW WAY - always redirect them to select application
    e2ePathCondLogic_routeBackToSelectApp(routingPackage, e2e_form_id);
  }, [routingPackage, e2e_form_id]);
  const handleContinueWaterfallPiping = async () => {
    const res = await e2eSubLogic_continuePipingWaterfall(
      pollForSubmission,
      handleError,
      params,
      DevSubmissionEnums.Approved,
    );
    if (res) {
      setSubmissionStatus(LenderSubmissionStatusE.submitted);
      setForceSubmission(true);
      setContinueWaterfallPiping(false);
    }
  };

  const handleMoveForwardAction = useCallback(async () => {
    // TODO E2E P0 - add handle move forward action
    /**
     * It's either going to be:
     *  - handleUnhappyPathRouting
     *    - if continueWaterfallPiping is false and there are additional paths, show, else false
     *  - handleContinueWaterfallPiping
     *   - Normal Settings:
     *      - if current path is waterfall and there are additional lenders in current path:
     *        - check waterfall condition - base it off of that whether to show or not
     *      - if not waterfall:
     *        - don't show
     *
     *  LAA Setting:
     *  - we will be using laa_settings.e2e_configs.applicant_flow.move_forward_conditions
     *    - handleContinueWaterfallPiping
     *      - laaSettings.continue_waterfall.lender_responses = submission.lender_response
     *      - ensure that there are additional lendersin the current path
     *    - handleContinueWaterfallPiping
     *      - continueWaterfall is false
     *      - laaSettings.continue_next_path.lender_responses = submission.lender_responses
     *
     *  Notes:
     *  - only applies once the user has submitted
     */
    if (!showContinueWaterfallPiping && showNotHappyCard) {
      setShowMoveForwardCard(false);
      return handleUnhappyPathRouting();
    } else if (showContinueWaterfallPiping) {
      setShowMoveForwardCard(false);
      return await handleContinueWaterfallPiping();
    }
  }, [showContinueWaterfallPiping, showNotHappyCard, submissions]);

  return {
    fn: {
      handleApiPipe,
      handleUnhappyPathRouting,
      handleContinueWaterfallPiping,
      handleMoveForwardAction,
    },
    v: {
      offers,
      notOffers,
      showNotHappyCard,
      hasRedirects,
      submittedTabs,
      submissionStatus,
      failedLenderIds,
      allDeclined,
      haveE2eLendersResponded,
      showContinueWaterfallPiping,
      showMoveForwardCard,
      showErrorPage,
      combinedResponses,
      autoApprovals,
    },
  };
};
